import { connectToApi } from "@/helpers/connectToApi";
import { PlantWorkdaySetting } from "@/models/PlantWorkdaySetting";

type UpdatePlantWorkdaySettingParam = {
  plant_id: number;
  workdays_iso: number[];
  is_holiday_off: boolean;
};

type UpdatePlantWorkdaySettingApiResponse = {
  message: string;
};

export async function requestUpdatePlantWorkdaySetting(
  param: UpdatePlantWorkdaySettingParam,
): Promise<PlantWorkdaySetting> {
  await connectToApi<UpdatePlantWorkdaySettingApiResponse>({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    url: "/api/updatePlantWorkdaySetting",
    data: {
      plant_id: param.plant_id,
      workdays_iso: param.workdays_iso,
      is_holiday_off: param.is_holiday_off,
    },
  }).catch((e) => {
    throw e;
  });

  return {
    plant_id: param.plant_id,
    workdays_iso: param.workdays_iso,
    is_holiday_off: param.is_holiday_off,
  };
}
