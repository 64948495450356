export type PlantWorkdaySetting = {
  plant_id: number;
  workdays_iso: number[];
  is_holiday_off: boolean;
};

type IsoWeekDay = {
  label: string;
  value: number;
};

export const isoWeeks: () => IsoWeekDay[] = () => [
  { label: "月曜日", value: 1 },
  { label: "火曜日", value: 2 },
  { label: "水曜日", value: 3 },
  { label: "木曜日", value: 4 },
  { label: "金曜日", value: 5 },
  { label: "土曜日", value: 6 },
  { label: "日曜日", value: 7 },
];
