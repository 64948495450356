import { connectToApi } from "@/helpers/connectToApi";
import { PlantWorkdaySetting } from "@/models/PlantWorkdaySetting";

type GetPlantWorkdaySettingApiResponse = {
  plant_id: number;
  workdays_iso: number[];
  is_holiday_off: boolean;
};

/**
 * プラントの整備日設定を取得するAPIを実行
 * @param plantId プラントID
 * @returns プラントの整備日設定
 */
export async function requestGetPlantWorkdaySetting(
  plantId: string,
): Promise<PlantWorkdaySetting> {
  const response = await connectToApi<GetPlantWorkdaySettingApiResponse>({
    method: "GET",
    url: "/api/getPlantWorkdaySetting",
    params: {
      plant_id: plantId,
    },
  });

  return {
    plant_id: response.data.plant_id,
    workdays_iso: response.data.workdays_iso,
    is_holiday_off: response.data.is_holiday_off,
  };
}
