<template>
  <div class="text-center">
    <v-dialog v-model="innerOpenStatus" width="50%" scrollable>
      <v-card>
        <v-card-title> 整備日を設定 </v-card-title>
        <v-card-text>
          <div class="mb-6">
            <p class="text-body-1">整備可能曜日を設定する</p>
            <span class="text-body-2"
              >選択した曜日にのみ、整備作業が計画されます。</span
            >
            <v-checkbox
              v-for="day in isoWeeks()"
              :key="`${day.value}-workday`"
              v-model="state.selectedWorkDays"
              density="compact"
              :hide-details="true"
              :label="day.label"
              :value="day.value"
              color="primary"
              class="mt-1"
              style="height: 30px"
            />
          </div>
          <div>
            <p class="text-body-1">祝日の整備有無を設定する</p>
            <span class="text-body-2"
              >「祝日は整備日」を選択すると、祝日にも整備作業が計画されます。<br />
              「祝日は整備日ではない」を選択すると、祝日には計画されません。</span
            >
            <v-radio-group
              v-model="state.isHolidayOff"
              density="compact"
              :hide-details="true"
              color="primary"
              class="mt-1"
            >
              <v-radio label="祝日は整備日" :value="false" class="mt-2">
              </v-radio>
              <v-radio label="祝日は整備日ではない" :value="true" class="mt-1">
              </v-radio>
            </v-radio-group>
          </div>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-btn variant="outlined" @click="innerOpenStatus = false"
            >キャンセル</v-btn
          >
          <v-btn
            color="primary"
            variant="flat"
            :loading="state.updateLoading"
            @click="updatePlantWorkdaySetting"
          >
            設定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <overlay-loading :is-loading="state.loading" />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from "vue";
import { AlertSnackBarModel } from "@/models/snackbar";
import { isoWeeks } from "@/models/PlantWorkdaySetting";
import { useAuthoritiesStore } from "@/stores/authorities";
import { requestGetPlantWorkdaySetting } from "@/helpers/api/getPlantWorkdaySetting";
import { requestUpdatePlantWorkdaySetting } from "@/helpers/api/updatePlantWorkdaySetting";
import OverlayLoading from "@/components/OverlayLoading.vue";

type Emits = {
  OpenStatusUpdateRequest: [value: boolean];
  SnackBar: [snackBar: AlertSnackBarModel];
};

type Props = {
  plantWorkdaySettingDialogOpenStatus: boolean;
};

const prop = defineProps<Props>();

const emit = defineEmits<Emits>();

type State = {
  selectedWorkDays: number[];
  isHolidayOff: boolean | undefined;
  loading: boolean;
  updateLoading: boolean;
};

const state = reactive<State>({
  selectedWorkDays: [],
  isHolidayOff: undefined,
  loading: false,
  updateLoading: false,
});

const storeAuthorities = useAuthoritiesStore();

const innerOpenStatus = computed({
  get: () => prop.plantWorkdaySettingDialogOpenStatus,
  set: (value: boolean) => emit("OpenStatusUpdateRequest", value),
});

watch(innerOpenStatus, (value) => {
  if (value) {
    getPlantWorkdaySetting();
  }
});

const getPlantWorkdaySetting = async () => {
  state.loading = true;
  const plant_id = storeAuthorities.selectedAuthority.plantId;

  try {
    const res = await requestGetPlantWorkdaySetting(plant_id);
    state.selectedWorkDays = res.workdays_iso;
    state.isHolidayOff = res.is_holiday_off;
  } catch (e) {
    console.error("get_plant_workday_setting", e);
    const snackBar: AlertSnackBarModel = {
      isShow: true,
      type: "error",
      title: "",
      message: "整備日設定の取得に失敗しました。",
    };
    emit("SnackBar", snackBar);
  } finally {
    state.loading = false;
  }
};

const updatePlantWorkdaySetting = async () => {
  state.updateLoading = true;

  try {
    await requestUpdatePlantWorkdaySetting({
      plant_id: parseInt(storeAuthorities.selectedAuthority.plantId, 10),
      workdays_iso: state.selectedWorkDays,
      is_holiday_off: !!state.isHolidayOff,
    });

    innerOpenStatus.value = false;

    const snackBar: AlertSnackBarModel = {
      isShow: true,
      type: "success",
      title: "",
      message: "整備日設定を更新しました。",
    };
    emit("SnackBar", snackBar);
  } catch (e) {
    console.error("update_plant_workday_setting", e);
    const snackBar: AlertSnackBarModel = {
      isShow: true,
      type: "error",
      title: "",
      message: "整備日設定の更新に失敗しました。",
    };
    emit("SnackBar", snackBar);
  } finally {
    state.updateLoading = false;
  }
};
</script>

<style scoped></style>
